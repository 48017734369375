.date-picker {
    height: 80px;
    width: 100%;
}

.date-picker input {
    font-weight: bold;
    font-size: 28.5px !important;
    text-transform: uppercase;
    text-align: center;
}

.ant-picker-header-super-prev-btn {
    display: none;
}

.ant-picker-header-super-next-btn {
    display: none;
}